<template>
  <a-modal :width="1100" :maskClosable="false" :title="formData.id ? '编辑' : '新增'" :visible="visible"
    @cancel="handleCancel" @ok="handleSubmit"  okText="提交审核">
    <span style="color:red;">分类资质审核过程中，只有通过的分类在创建spu商品时可以展示。若编辑分类进入审核中，无法在创建时搜索到分类。</span>
    <a-tabs default-active-key="1" v-model="type" @change="callback">
      <a-tab-pane :key="1">
        <span slot="tab">
          商城
        </span>
      </a-tab-pane>
      <!-- <a-tab-pane :key="0">
        <span slot="tab">
          其他
        </span>
      </a-tab-pane> -->
    </a-tabs>
    
    <p>一级分类</p>
    <div v-if="classifyLeve1.length > 0" style="display: flex;flex-wrap: wrap;">
      <ul class="check-ul">
        <li :value="item.id" @click="levelChange1(item, index)" :class="{ 'activeBorder': item.isSel }"
          v-for="(item, index) in classifyLeve1" :key="item.id" style="margin: 4px;">
          {{ item.name }}
          <a-icon type="close-circle" v-if="item.isSel" @click.stop="removeLevel1(item, index)" />
        </li>
      </ul>
    </div>
    <div v-else style="width: 100%;">
      暂无
    </div>
    <p style="border-top: 1px solid #eee;padding-top: 10px;">二级分类<span v-if="twoName" style="color:red;margin-left: 10px;">({{ twoName }})</span></p>
    <div v-if="classifyLeve2.length > 0">
      <ul class="check-ul">
        <li :value="item.id" @click="levelChange2(item, index)" :class="{ 'activeBorder': item.isSel }"
          v-for="(item, index) in classifyLeve2" :key="item.id" style="margin: 4px;">
          {{ item.name }}
          <a-icon type="close-circle" v-if="item.isSel" @click.stop="removeLevel2(item, index)" />
        </li>
      </ul>
    </div>
    <div v-else style="width: 100%;">
      暂无
    </div>
    <p style="border-top: 1px solid #eee;padding-top: 10px;margin-top: 20px;">三级分类<span v-if="threeName" style="color:red;margin-left: 10px">({{ threeName
        }})</span></p>
    <div v-if="classifyLeve3.length > 0">
      <ul class="check-ul">
        <li :value="item.id" @click="levelChange3(item, index)" :class="{ 'activeBorder': item.isSel }"
          v-for="(item, index) in classifyLeve3" :key="item.id" style="margin: 4px;">
          {{ item.name }}
          <a-icon type="close-circle" v-if="item.isSel" @click.stop="removeLevel3(item, index)" />
        </li>
      </ul>
    </div>
    <div v-else>
      暂无
    </div>


  </a-modal>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    confirmLoading: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    pageType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      treeValue:[],
      treeData:[],
      moment,
      rules: {
        certificateType: [{ required: true, message: '请选择证件种类', trigger: 'change' }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      fileList: [],
      qiNiuAction: 'https://upload-z1.qiniup.com',
      qiNiuUploadData: {
        key: '',
        token: ''
      },
      getQiniuTokenIng: false,
      postData: {
        token: ''
      },
      dateStartTime: "",
      dateEndTime: "",
      langTime: false,//长期有效
      pId: 0,
      type: 1,//商城
      classifyLeve1: [],
      classifyLeve2: [],
      classifyLeve3: [],
      checkList1: [],
      checkList2: [],
      checkList3: [],
      level1Name: "",
      level2Name: "",
      level1Index: 0,
      selectList: [],
      twoName:"",
      threeName:""
    }
  },
  mounted() {
    this.getList();
  },
  methods: {

    callback() {
      this.checkList1 = []
      this.checkList2 = []
      this.checkList3 = []
      this.classifyLeve1 = []
      this.classifyLeve2 = []
      this.classifyLeve3 = []
      this.level1Name = ""
      this.level2Name = ""
      this.getList()
    },
    // 选中一级
    levelChange1(item, index) {
      this.twoName=item.name
      this.threeName = ""
      if (!this.addIdIfNotExists(this.selectList, item.id)) {
        this.selectList.push(item)
        this.classifyLeve1[index].isSel = true;
        request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + item.id, 'get').then(res => {
          this.classifyLeve2 = res.data.data;
          const idsInA = new Set(this.selectList.map(it => it.id));
          const idsInB = this.classifyLeve2.some(it => idsInA.has(it.id));
          if (idsInB) {
            // 有已选中
            this.classifyLeve2.forEach(it => {
              if (idsInA.has(it.id)) {
                it.isSel = true;
              }
            });
            const firstSelected = this.classifyLeve2.find(item => item.isSel === true);
            this.getLevel3Data(firstSelected.id)
          } else {
            this.classifyLeve3 = []
          }
        })
      } else {
        this.onChange([{ id: item.id }])
      }

    },
    // 选中2级
    levelChange2(item, index) {
      this.threeName=item.name
      if (!this.addIdIfNotExists(this.selectList, item.id)) {
        this.selectList.push(item)
        this.classifyLeve2[index].isSel = true;
      }
      request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + item.id, 'get').then(res => {
        this.classifyLeve3 = res.data.data;
        const idsInA = new Set(this.selectList.map(it => it.id));
        const idsInB = this.classifyLeve3.some(it => idsInA.has(it.id));
        // 首次选中
        if (!idsInB) {
          this.classifyLeve3.forEach((it, idx) => {
            if (!this.addIdIfNotExists(this.selectList, it.id)) {
              this.selectList.push(it)
              it.isSel = true
              this.$set(this.classifyLeve3, idx, { ...it, isSel: true });
            }
          })
        } else {
          // 有已选中
          this.classifyLeve3.forEach((it,idx) => {
            if (idsInA.has(it.id)) {
              it.isSel = true;
              this.$set(this.classifyLeve3, idx, { ...it, isSel: true });
            }
          });
        }
      })
    },
    // 选3级
    levelChange3(item, index) {
      if (!this.addIdIfNotExists(this.selectList, item.id)) {
        this.selectList.push(item)
        this.classifyLeve3[index].isSel = true;
        this.$set(this.classifyLeve3, index, { ...item, isSel: true });
      }
    },
    // 删除一级分类
    removeLevel1(item, index) {
      let delId = item.id;
      this.selectList = this.selectList.filter(it => it.id != delId);
      this.$set(this.classifyLeve1, index, {
        ...this.classifyLeve1[index],
        isSel: false
      });
      this.selectList.filter((it, idx) => {
        if (it.pId == delId) {
          this.removeLevel2(it, idx)
        }
      });
    },
    // 删除二级分类
    removeLevel2(item) {
      let delId = item.id;
      this.selectList = this.selectList.filter(it => it.id != delId);
      this.selectList = this.selectList.filter(it => it.pId != delId);
      this.classifyLeve2.filter(it => {
        if (it.id == delId) {
          it.isSel = false
        }
      })
      request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + delId, 'get').then(res => {
        this.classifyLeve3 = res.data.data;
      })
      // this.getLevel3Data(delId)
    },
    // 加载三级数据
    getLevel3Data(id) {
      request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + id, 'get').then(res => {
        this.classifyLeve3 = res.data.data;
        let hasCommonId = false;
        this.classifyLeve3.forEach(itemB => {
          if (this.selectList.some(itemA => itemA.id === itemB.id)) {
            itemB.isSel = true;
            hasCommonId = true;
          }
        });
        if (!hasCommonId) {
          this.classifyLeve3.forEach(itemB => itemB.isSel = true);
          this.selectList = [...this.selectList, ...this.classifyLeve3];
        }
      })
    },
    removeLevel3(item, index) {
      this.selectList = this.removeById(this.selectList, item.id)
      this.$set(this.classifyLeve3, index, {
        ...this.classifyLeve3[index],
        isSel: false
      });
    },
    removeById(array, nameId) {
      // 过滤掉具有指定 id 的元素
      return array.filter(item => item.id !== nameId);
    },
    // 检查数组中是否已有该 id
    addIdIfNotExists(array, id) {
      console.log(array, id)
      const exists = array.some(item => item.id == id);
      return exists
    },
    getList() {
      request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + this.pId, 'get').then(res => {
        this.classifyLeve1 = res.data.data;
        if (this.formData.classifyList.length > 0) {
          this.selectList = JSON.parse(JSON.stringify(this.formData.classifyList))
          this.classifyLeve1.forEach(item => {
            this.formData.classifyList.forEach(son => {
              if (item.id == son.id) {
                item.isSel = true;
                this.twoName = item.name
                // this.selectList.push(item)
              }
            })
          })
          let ids = this.selectList.find(item=> item.level == 1)
          this.onChange([ids])
        }
      })
    },
    onChange(value) {
      let id = value[value.length - 1].id
      request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + id, 'get').then(res => {
        this.classifyLeve2 = res.data.data;
        this.classifyLeve3 = []
        if (this.formData.id) {
          // 获取已选中的二级分类
            this.selectList.forEach(item => {
              this.classifyLeve2.forEach(son => {
                if (item.id == son.id) {
                  son.isSel = true;
                  this.threeName = son.name
                }
              })
            })
            let ids = this.selectList.find(item=> item.level == 2 && item.pId == id)
            this.onChangeTwo([ids])
        } else {
          // 新增
          let ids = []
          this.selectList.forEach(item => {
            this.classifyLeve2.forEach(son => {
              if (item.id == son.id) {
                son.isSel = true;
                ids.push(item.id)
                if (!this.addIdIfNotExists(this.selectList, item.id)) {
                  this.selectList.push(item)
                }
              }
            })
          })
          if (ids.length > 0) {
            this.onChangeTwo([{ id: ids[0] }])
          }
        }
      })

    },
    onChangeTwo(value) {
      if (this.formData.id) {
        let id = value[value.length - 1].id
        request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + id, 'get').then(res => {
          this.classifyLeve3 = res.data.data;
            this.selectList.forEach(item => {
              this.classifyLeve3.forEach((son, index) => {
                if (item.id == son.id) {
                  this.$set(this.classifyLeve3, index, { ...son, isSel: true });
                }
              });
            });
        })
      } else {
        let id = value[value.length - 1].id
        request(process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + this.type + '/' + id, 'get').then(res => {
          this.classifyLeve3 = res.data.data;
          if (this.classifyLeve3.length > 0) {
            this.selectList.forEach(item => {
              this.classifyLeve3.forEach(son => {
                if (item.id == son.id) {
                  son.isSel = true
                  if (!this.addIdIfNotExists(this.selectList, item.id)) {
                    this.selectList.push(item)
                  }
                }
              })
            });
          }
        })
      }
    },
    removeFile() {
      this.formData.fileUrl = ""
      this.fileList = []
    },
    handleCancel() {
      // 处理取消操作
      this.$emit('cancel');
    },
    handleSubmit() {
      // 处理表单提交
      if (this.selectList.length == 0) {
        this.$message.error('请选择分类！')
        return false;
      }
      this.confirmLoading = true;
      let diArray = this.selectList.map(obj => obj.classifyId ? obj.classifyId : obj.id);
      diArray = [...new Set(diArray)]
      let obj = {
        "classifyIdList": diArray
      }
      if (this.formData.id) {
        obj.id = this.formData.id
      }
      this.$emit('submit', obj)
      this.confirmLoading = true;

    },
  }
};
</script>
<style scoped>
.title-text {
  font-weight: 700;
  font-size: 16px;
  color: #333;
}

.ify-list {
  display: flex;
  flex-wrap: wrap
}

.ify-list img {
  width: 60px;
  height: 60px;
}

.ify-list div {
  width: 60px;
  height: 60px;
  line-height: 80px;
  margin-right: 20px;
}

.check-ul {
  display: flex;
  flex-wrap: wrap;
}

.check-ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 40px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #eee;
  cursor: pointer;
}

.check-ul .activeBorder {
  border: 1px solid #3f8bff;
  color: #3f8bff;
}
</style>
