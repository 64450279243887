<template>
    <a-card style="background-color: #fff;">
        <a-tabs default-active-key="" @change="callback">
            <a-tab-pane key="" tab="全部">
            </a-tab-pane>
            <a-tab-pane key="1" tab="审核中">
            </a-tab-pane>
            <a-tab-pane key="2" tab="审核通过">
            </a-tab-pane>
            <a-tab-pane key="3" tab="审核未通过">
            </a-tab-pane>
        </a-tabs>
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="8" :sm="24" style="max-width: 400px;">
                            <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-button @click="showModal('add')">新增</a-button>
                    </a-row>
                    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
                    queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                </div>
            </a-form>
        </div>
        <div>
            <a-table bordered :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">

                <div slot="fileUrl" slot-scope="record">
                    <a v-if="record" @click="fileLink(record)">预览文件</a>
                </div>
                <div slot="action" slot-scope="record">
                    <a v-if="record.auditStatus != 1" @click="showModal('edit', record)"
                        style="margin-left: 5px;">编辑</a>
                    <a v-if="record.auditStatus == 1" style="margin-left: 5px;color:#ccc;" >编辑</a>
                </div>

                <template slot="startTime" slot-scope="text"> {{ formatDate(text) }}</template>
                <template slot="endTime" slot-scope="text">
                    <span v-if="text != '4070880000000'">{{ formatDate(text) }}</span>
                    <span v-else>长期</span>
                </template>
                <template slot="createTime" slot-scope="text">{{ formatDateMin(text) }}</template>
                <template slot="modifyTime" slot-scope="text"> {{ formatDateMin(text) }}</template>
                
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>

        <FormModal v-if="isModalVisible" :visible="isModalVisible" :confirmLoading="confirmLoading" :formData="formData"
            @cancel="handleCancel" :pageType="1" @submit="submit" />
    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import FormModal from '../components/classifyModal.vue'
const columns = [
    { title: '序号',customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '证件种类', dataIndex: 'typeDesc', align: 'center' },
    { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' } },
    { title: '创建时间',  dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
    { title: '审核状态', dataIndex: 'auditStatusDesc', align: 'center', },
    { title: '驳回原因', dataIndex: 'auditReason', align: 'center', },
    { title: '操作', align: 'center', scopedSlots: { customRender: 'action' } },
]
export default {
    name: 'QueryList',
    components: {  FormModal },
    data() {
        return {
            moment,
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
                type:500
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
           
            currentPage: 1,
           
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {},
            categoryList: [{ id: 101, name: '营业执照' }, { id: 102, name: '一般纳税人证明' }, { id: 103, name: '基本开户信息表' }, { id: 104, name: '食品经营许可证' }],
            userInfo:{},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        callback(e){
            this.queryData.auditStatus = e;    
            this.getData();
        },
        dateChange(date,dateString) {
            this.dateValue = date;
            this.queryData.dateMin = dateString[0]
            this.queryData.dateMax = dateString[1]
        },
        fileLink(url) {
            window.open(url, '_blank')
        },
        showModal(type, data) {
            // 根据类型区分是新增还是编辑
            if (type === 'add') {
                this.formData = {}
                this.isModalVisible = true; 
            } else {
                this.getUserInfo(data.id)
            }
        },        
        getUserInfo(id){
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/detailClassify/'+id + '/0', 'post', {}).then(res => {
                this.formData = res.data.data;
                this.formData.classifyList.forEach(item=>{
                    item.id = item.classifyId
                })
                this.isModalVisible = true; 
            })
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/updateClassify', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    } else {
                        this.$message.error(res.data.message)
                    }
                    this.confirmLoading = false
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/saveClassify', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    } else {
                        this.$message.error(res.data.message)
                    }
                    this.confirmLoading = false
                })
            }
        },
        updateStatus(id, status) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/updateStatus', 'post', {
                id, status
            }).then(res => {
                if (res.data.code == 200) {
                    this.getData();
                    this.$message.success('操作成功！')
                    this.isModalVisible = false;
                }
            })

        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
                type:500,
                auditStatus:this.queryData.auditStatus
            }
            this.dateValue = ''
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            // const hours = date.getHours().toString().padStart(2, '0');
            // const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            // let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            let result = year + '-' + month + '-' + day
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>